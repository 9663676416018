import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react'
import { styled } from '@sans-souci/styles'
import { SectionPhilosophySliderProps } from '@sans-souci/models'
import { Button, SectionWrap } from '@sans-souci/components'
import { autoTextSize } from 'auto-text-size'
import { useTranslation } from 'react-i18next'
import { Slide } from './Slide'
import { ArrowLeftShort, ArrowRightShort } from '@sans-souci/icons'

const Root = styled('div', {
  overflowX: 'hidden',
  position: 'relative',
  zIndex: 1,

  variants: {
    darkTheme: {
      true: {
        background: '$black',
        color: '$beige',
      },
      false: {},
    },
  },
  $lin: {
    paddingBottom: 'XXL',
    paddingTop: 'XL',
  },
})

const Background = styled('div', {
  zIndex: 0,
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  pointerEvents: 'none',
  display: 'grid',
  justifyContent: 'center',
  paddingLeft: '$containerMarginMobile',
  paddingRight: '$containerMarginMobile',
  h2: {
    textAlign: 'center',
    $projectFont: 'heading01',
    alignSelf: 'center',
  },
  variants: {
    loaded: {
      false: {
        opacity: 0,
      },
    },
  },
})

const SliderRoot = styled('div', {
  width: '100%',
  gridArea: '1/1',
  display: 'grid',
  gridAutoFlow: 'column',
  gridGap: '9vw',
  overflowX: 'scroll',
  scrollSnapType: 'x mandatory',
  gridAutoColumns: '70vw',
  padding: '0 50vw',
  '@md': {
    gridAutoColumns: '40vw',
  },
  '&::-webkit-scrollbar': {
    display: 'none',
  },
})
const ArrowsWrap = styled('div', {
  display: 'grid',
  justifyContent: 'center',
  alignItems: 'center',
  gridAutoFlow: 'column',
  gap: '$32',
  $lin: {
    paddingBottom: 'S',
  },
})
const ArrowsTitle = styled('span', {
  $projectFont: 'heading04',
})
function manageSlideIntersecting(
  entry: IntersectionObserverEntry,
  slide: Element,
  disableButton: Dispatch<SetStateAction<boolean>>,
) {
  if (entry.target === slide) {
    disableButton(entry.isIntersecting)
  }
}

const StyledButton = styled(Button, {
  '&:disabled': {
    color: '$grey',
  },
})
export const SectionPhilosophySlider: FC<SectionPhilosophySliderProps> = ({
  title,
  slides,
  darkTheme = false,
  _key,
}) => {
  const { t } = useTranslation('sectionPhilosophySlider')
  const [loaded, setLoaded] = useState(false)
  const titleWrapRef = useRef<HTMLDivElement>(null)
  const sliderRef = useRef<HTMLDivElement | null>(null)
  const [isFirstSlideVisible, setIsFirstSlideVisible] = useState(true)
  const [isLastSlideVisible, setIsLastSlideVisible] = useState(false)
  const observerRef = useRef<null | IntersectionObserver>(null)

  const scrollBySlides = (numberOfSlides: number) => {
    if (sliderRef.current) {
      const slides = sliderRef.current.childNodes as NodeListOf<Element>
      const slideWidth = slides[0].getBoundingClientRect().width
      const gutterWidth =
        slides[1].getBoundingClientRect().left -
        slides[0].getBoundingClientRect().right
      const destination =
        numberOfSlides * slideWidth + numberOfSlides * gutterWidth

      sliderRef.current?.scrollBy({
        left: destination,
        behavior: 'smooth',
      })
    }

    const firstSlideRef = sliderRef.current?.firstChild as Element
    const lastSlideRef = sliderRef.current?.lastChild as Element

    if (observerRef.current) observerRef.current.disconnect()

    observerRef.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          manageSlideIntersecting(entry, firstSlideRef, setIsFirstSlideVisible)
          manageSlideIntersecting(entry, lastSlideRef, setIsLastSlideVisible)
        })
      },
      {
        threshold: 0.9,
        rootMargin: '100% 0% 100% 0%',
      },
    )

    observerRef.current?.observe(firstSlideRef)
    observerRef.current?.observe(lastSlideRef)
  }
  const scrollToSlide = (slideIndex: number) => {
    if (sliderRef.current) {
      const slides = sliderRef.current.childNodes as NodeListOf<Element>
      const slideWidth = slides[0].getBoundingClientRect().width
      const gutterWidth =
        slides[1].getBoundingClientRect().left -
        slides[0].getBoundingClientRect().right

      let destination = 0
      destination = (slideWidth + gutterWidth) * slideIndex

      sliderRef.current?.scrollTo({
        left: destination,
        behavior: 'smooth',
      })
    }
  }
  useEffect(() => {
    if (!titleWrapRef?.current) return
    const updateTextSize = autoTextSize({
      innerEl: titleWrapRef.current.getElementsByTagName('h2')[0],
      maxFontSizePx: 180,
      minFontSizePx: 53,
      mode: 'oneline',
      fontSizePrecisionPx: 0.1,
      containerEl: titleWrapRef.current,
    })
    updateTextSize()
    setLoaded(true)
    return () => {
      updateTextSize.disconnect()
    }
  }, [])
  return (
    <SectionWrap
      type={'withBorders'}
      theme={darkTheme ? 'dark' : 'light'}
      id={_key}
    >
      <Root darkTheme={darkTheme}>
        <ArrowsWrap>
          <StyledButton
            onClick={() => scrollBySlides(-1)}
            disabled={isFirstSlideVisible}
          >
            <ArrowLeftShort />
          </StyledButton>
          <ArrowsTitle>{title}</ArrowsTitle>
          <StyledButton
            onClick={() => scrollBySlides(1)}
            disabled={isLastSlideVisible}
          >
            <ArrowRightShort />
          </StyledButton>
        </ArrowsWrap>
        <Background ref={titleWrapRef} loaded={loaded}>
          <h2>{title}</h2>
        </Background>
        <SliderRoot ref={sliderRef}>
          {slides?.map((slide, i) => (
            <Slide
              {...slide}
              handleClick={() => scrollToSlide(i)}
              ariaLabel={t('slideAriaLabel', { value: i })}
              key={i}
            />
          ))}
        </SliderRoot>
      </Root>
    </SectionWrap>
  )
}
