import { FC } from 'react'
import { styled } from '@sans-souci/styles'
import { PhilosophySlideType } from '@sans-souci/models'
import { Button, Link, PortableText } from '@sans-souci/components'
import { useInView } from 'react-intersection-observer'

const Root = styled('div', {
  position: 'relative',
  display: 'grid',
  zIndex: 1,
  aspectRatio: 1,
  background: '$pink',
  scrollSnapAlign: 'center',
  color: '$grey',
  '-webkit-tap-highlight-color': 'transparent',
  variants: {
    color: {
      yellow: {
        backgroundColor: '$yellow',
      },
      pink: {
        backgroundColor: '$pink',
      },
      grey: {
        backgroundColor: '$lightGrey',
      },
    },
  },
})

const SlideButton = styled(Button, {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  cursor: 'pointer',
  zIndex: 0,

  variants: {
    inView: {
      true: {
        pointerEvents: 'none',
      },
      false: {},
    },
  },

  '&:focus-visible': {
    outline: '$focusColor solid 3px',
    outlineOffset: '-3px',
  },
})

const SlideContent = styled('div', {
  display: 'grid',
  gridTemplateRows: 'min-content min-content auto',
  gap: '$20',
  pointerEvents: 'all',
  height: '100%',

  $lin: {
    paddingLeft: [20, 30],
    paddingRight: [20, 30],
  },

  paddingBottom: '$36',
  paddingTop: '$36',
})

const SlideTitle = styled('h3', {
  $projectFont: 'heading03',
})

const SlideLink = styled(Link, {
  width: 'fit-content',
  alignSelf: 'end',
  zIndex: 1,
})

export const Slide: FC<
  PhilosophySlideType & {
    handleClick?: () => void
    ariaLabel?: string
  }
> = ({ title, text, color, ctaLink, handleClick, ariaLabel }) => {
  const { ref, inView } = useInView({
    threshold: 1,
    rootMargin: '100% 0%',
  })

  return (
    <Root color={color} ref={ref}>
      <SlideButton
        onClick={handleClick}
        ariaLabel={ariaLabel}
        inView={inView}
      />
      <SlideContent>
        {title && <SlideTitle>{title}</SlideTitle>}
        {text && <PortableText value={text} />}
        {ctaLink && (
          <SlideLink
            {...ctaLink.payload}
            appearance={
              color === 'yellow' ? 'ButtonPrimaryGrey' : 'ButtonPrimaryWhite'
            }
          >
            {ctaLink.title}
          </SlideLink>
        )}
      </SlideContent>
    </Root>
  )
}
